<!--
 * @Author: Akko
 * @Description: 加上切换tab的Utable表格组件(留学)
-->
<template>
    <div>
        <div class="choose_box">
            <!-- 输入框 -->
            <div class="input-cont">
                <el-input v-model="dataName" placeholder="请输入资料名称" @keyup.enter.native="searchInfo" />
                <el-button type="primary" :loading="searchLoading" @click="searchInfo">搜索</el-button>
            </div>
            <!-- 留学新增 -->
            <div class="input-cont2">
                <el-select v-model="schemePosition" placeholder="请选择方案定位" clearable @change="schemeChange">
                    <el-option v-for="item in options" :key="item.id" :label="item.schoolMajor" :value="item.id" />
                </el-select>
            </div>
            <div class="tips_txt">选择方案后可确认资料提交</div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="(item,index) in tabArr" :key="index" :label="item.label" :name="item.key" />
            <!-- table表单 -->
            <Utable :data="tableData" height="500" stripe border :cm-select="false" select-key="id" table-id="tableDetail">
                <el-table-column label="方案定位" align="center">
                    <div slot-scope="scope">
                        <span>{{ scope.row.schoolMajor }}</span>
                    </div>
                </el-table-column>
                <el-table-column prop="name" label="资料名称" align="center" show-overflow-tooltip />
                <el-table-column label="申请人" align="center" width="100">
                    <div slot-scope="scope">
                        <span style="width: 100px">{{ scope.row.applicantName }}</span>
                    </div>
                </el-table-column>
                <el-table-column align="center" label="重要说明" width="150">
                    <template slot-scope="scope">
                        <el-popover
                            placement="top"
                            trigger="hover"
                            width="400"
                        >
                            <span>{{ scope.row.remark }}</span>
                            <span slot="reference" class="remark">{{ scope.row.remark }}</span>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="模版" width="70">
                    <div slot-scope="scope">
                        <div v-if="scope.row.fileUrl===''" class="template-inlinebox">暂无</div>
                        <div v-else class="blue-color" @click="seeTemplate(scope.row.fileUrl)">查看</div>
                        <!-- <div v-if="scope.row.documentUrl!==''" class="blue-color" @click="downLoad(scope.row)">下载</div> -->
                    </div>
                </el-table-column>
                <el-table-column align="center" label="上传要求" width="100">
                    <div slot-scope="scope">
                        <span>{{ scope.row.uploadRequirement==1?"如无可不提供":"必须提供" }}</span>
                    </div>
                </el-table-column>
                <!-- <el-table-column label="最新上传时间" width="120" prop="submitTime">
                    <div slot-scope="scope">
                        <div class="newest-time">{{ scope.row.submitTime }}</div>
                    </div>
                </el-table-column> -->
                <el-table-column align="center" label="审核结果" width="90">
                    <div slot-scope="scope">
                        <!-- 审核结果 0：未审核，1：通过 2：驳回  -->
                        <span v-if="scope.row.checkStatus===0" style="color:#e6a23c">{{ scope.row.checkStatus | findingsFilter }}</span>
                        <span v-if="scope.row.checkStatus===1" style="color:#67c23a">{{ scope.row.checkStatus | findingsFilter }}</span>
                        <span v-if="scope.row.checkStatus===2&&scope.row.auditContent.length<10" class="two-row" style="color:#f56c6c">{{ scope.row.checkStatus | findingsFilter }}（{{ scope.row.auditContent }}）</span>
                        <!-- 驳回原因 -->
                        <el-tooltip v-if="scope.row.checkStatus===2&&scope.row.auditContent.length>=10" placement="top">
                            <div slot="content" class="set-width">{{ scope.row.auditContent }}</div>
                            <span class="two-row hover-row" style="color:#f56c6c">{{ scope.row.checkStatus | findingsFilter }}（{{ scope.row.auditContent }}）</span>
                        </el-tooltip>
                        <span v-if="scope.row.checkStatus===3">{{ scope.row.checkStatus | findingsFilter }}</span>
                    </div>
                </el-table-column>
                <el-table-column align="center" label="操作(仅支持pdf/png/jpg/jpeg/word格式)" width="350">
                    <div slot-scope="scope">
                        <div v-if="scope.row.checkStatus!==1&&!scope.row.noUploading" class="upload-data blue-color">
                            <UploadFile :id="scope.row.id" acl="private" :file-names="fileNames" class="inblock-box" button-text="请上传" :limit="20" :max-size="50" :file-type="['image/png','application/vnd.openxmlformats-officedocument.wordprocessingml.document','image/jpg','application/pdf','image/jpeg','application/msword','application/vnd.ms-powerpoint','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-excel']" @success="inputUpload($event,scope.row.id)" />
                        </div>
                        <div v-for="(item2,index2) in scope.row.contentUrl" :key="index2">
                            <span class="file-name">{{ item2.name }}</span>
                            <span class="see-fold" @click="$utils.preview(item2.url)">预览</span>
                            <span v-if="scope.row.checkStatus!==1" class="delete-img" @click="deleteFile(scope.row.id,item2)">删除</span>
                        </div>
                    </div>
                </el-table-column>
            </Utable>
        </el-tabs>
        <div v-if="activeName==='1'&&seeSubmit==1" class="form-box">
            <div class="sure-tip">请确认您所上传的资料真实有效，如因提供虚假资料而对申请产生不良后果，将由您自己负责</div>
            <div class="tip-btn">
                <el-checkbox v-model="sureChoice" @change="checkChange">我确认以上资料真实有效,并知悉其中内容</el-checkbox>
                <div v-if="checkChanges&&sureChoice===''" class="message-color">{{ message }}</div>
            </div>
            <div class="right-float">
                <div :class="sureChoice?'vblue-bg':'vgrey-bg'" class="submit-btn" @click="submit">提交</div>
            </div>
        </div>
    </div>
</template>

<script lang="javascript">
// import CustomDialog from "@/components/CustomDialog/CustomDialog.vue";
import Utable from "@/components/Utable/Utable.vue";
import { isImageType } from '@/utils/utils'
import {
    suppleList,
    deleteF,
    search,
    fileSubmit,
    taskStatus,
    uploading,
    orderPlan,
    handleAlter
} from "@/api/workfow.js";
export default {
    name: "GenerForm",
    components: {
        Utable,
        // CustomDialog,
    },
    data() {
        return {
            //key 1初审资料  2补件 3面试 4面试补件 5续签 6续签补件 7永居 8永居补件 9 粉签 10 粉签补件
            tabArr: [
                {
                    label: "申请阶段",
                    key: "1",
                },
                {
                    label: "学生签证阶段",
                    key: "9",
                },
                {
                    label: "续期阶段（IANG签证）",
                    key: "5",
                },
            ],
            tableData: [],
            activeName: "1",
            showClose: false,
            dataName: "", //资料名称
            schemePosition: "", //方案定位
            sureChoice: false,
            seeSubmit: "", // 0-不显示  1-显示  only 初审阶段有提交 其他的都不需要提交
            message: "",
            searchLoading: false,
            options: [],
            checkChanges: false,
            fileNames: '',
        };
    },
    watch: {
        sureChoice(val, newval) {
            if (val) {
                this.message = "";
                this.seeBlue = true;
            } else {
                this.message = "请勾选确认按钮后再提交";
                this.seeBlue = false;
            }
        },
    },
    created() {
        this.$store.commit("user");
        this.getTableData();
        this.orderPlanFun();
        if (this.activeName === "1") {
            this.getSubmitBlock();
        }
        this.fileNames = JSON.parse(localStorage.getItem("user")).orderId + '' || '';
    },
    methods: {
        // 方案切换
        schemeChange(e) {
            console.log(e);
            this.getTableData();
            this.getSubmitBlock();
            this.sureChoice = false;
            this.checkChanges = false;
        },
        checkChange() {
            this.checkChanges = true;
        },
        // 留学方案定位下拉框
        orderPlanFun() {
            orderPlan({
                orderId: this.$store.state.user.orderId,
            }).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.options = res.data.orderPlanList;
                }
            });
        },
        //提交部分是否渲染
        getSubmitBlock() {
            taskStatus({
                orderId: this.$store.state.user.orderId,
                planId: this.schemePosition,
            }).then((res) => {
                if (res.code == 200) {
                    this.seeSubmit = res.data.data;
                }
            });
        },
        //渲染Table数据
        getTableData() {
            let params = {
                orderId:this.$store.state.user.orderId,
                node: this.activeName,
                planId: this.schemePosition || "",
            };
            suppleList(params).then((res) => {
                if (res.code === 200) {
                    this.tableData = res.data;
                    this.tableData.map((item, index) => {
                        if (typeof item.submitTime == "undefined") {
                            item.submitTime = "";
                        }
                        // 驳回消息处理
                        if (!item.auditContent) {
                            item.auditContent = "";
                        }
                        item.path = "";

                        // 限制上传次数 最大6次
                        if (item.contentUrl !== null) {
                            item.noUploading =
                                item.contentUrl.length >= 20 ? true : false;
                        }
                    });
                }
            });
        },
        //切换Tab标签
        handleClick() {
            this.getTableData();
            this.getSubmitBlock();
        },
        //查看模版
        async seeTemplate(url) {
            console.log(1111)
            let urls = JSON.parse(url)[0].url
            const index = urls.lastIndexOf('.')
            const str = urls.substr(index + 1)
            console.log(str)
            const isImage = isImageType(str)
            if (isImage) {
                this.$utils.preview(urls);
            } else {
                window.open(await this.$utils.previewUrl(urls, JSON.parse(url)[0].name), '_blank')
            }
        },
        // 下载表格
        downLoad(row) {
            let data = JSON.parse(row.documentUrl)[0];
            this.$utils.downLoadData(data.url, data.name);
        },
        //删除上传文件
        deleteFile(id, fileInfo) {
            this.$confirm("确认要删除该资料信息吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let params = {
                        id: id,
                        fileInfo: fileInfo,
                    };
                    delete params.fileInfo.type;
                    deleteF(params).then((res) => {
                        if (res.code == 200) {
                            this.$message.success("删除成功");
                            //重新渲染数据
                            this.getTableData();
                        }
                    });
                })
                .catch(() => {});
        },
        //搜索输入框
        async searchInfo() {
            this.searchLoading = true;
            let params = {
                orderId: this.$store.state.user.orderId,
                name: this.dataName,
                node: this.activeName,
            };
            let res = await search(params);
            this.searchLoading = false;
            if (res.code == 200) {
                this.tableData = res.data;
            }
        },
        // 留学必选方案id
        choosePlan() {
            this.schemePosition === ""
                ? this.$message.error("请选择方案定位")
                : this.submit();
        },
        submit() {
            if (this.sureChoice) {
                //提交
                this.message = "";
                fileSubmit({
                    orderId: this.$store.state.user.orderId,
                    node: this.activeName,
                    planId: this.schemePosition,
                }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success("提交成功");
                        this.seeSubmit = 2; //提交完一次之后,不会显示提交按钮部分
                    }
                });
            } else {
                this.message = "请勾选确认按钮后再提交";
            }
        },
        //上传文件的事件
        inputUpload(e, id) {
            delete e.fileInfo.type;
            uploading(e).then((res) => {
                //重新渲染数据
                handleAlter({order_id: this.$store.state.user.orderId, id})
                this.getTableData();
            });
        },
    },
};
</script>

<style lang="less" scoped>
/deep/.el-table-wrapper {
    // border: none;
}

.two-row {
    width: 100%;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    &.hover-row {
        cursor: pointer;
    }
}

.tips_txt {
    margin: 50px 20px 20px 0;
    color: #c0c4cc;
}

.set-width {
    max-width: 300px;
}

.blue-color {
    display: inline-block;
    color: #409eff;
    cursor: pointer;
    margin-right: 15px;
}

.template-inlinebox {
    display: inline-block;
    margin-right: 15px;
}

.newest-time {
    width: 76px;
}

.dialog-footer {
    text-align: center;
}

.file-name {
    color: #409eff;
    margin-left: 10px;
    width: 200px;
    display: inline-block;
}

.see-fold {
    color: #409eff;
    cursor: pointer;
    margin-left: 10px;
}

.delete-img {
    color: #409eff;
    cursor: pointer;
    margin-left: 10px;
}

.explain-img {
    width: 20px;
    height: 20px;
    background: rgba(255, 165, 0);
    border-radius: 12px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    color: #ffffff;
}

.t-text {
    font-size: 16px;
    display: inline-block;
    color: #303133;
    margin-left: 10px;
}

.tip-cont {
    margin-top: 20px;
}
.choose_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.input-cont {
    margin: 40px 20px 20px 0;
    width: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.input-cont2 {
    margin: 40px 20px 20px 0;
}

/deep/.el-input__inner {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.sure-title {
    width: 100%;
    height: 144px;
    background: #f5f7fa;
    margin-top: 32px;
}

.sure-tip {
    font-size: 14px;

    font-weight: 400;
    color: #606266;
    padding-top: 16px;
    margin-left: 16px;
    margin-bottom: 16px;
}

.tip-btn {
    padding-left: 16px;
    padding-bottom: 12px;
}

.right-float {
    float: right;
    padding-right: 16px;
    position: absolute;
    top: 88px;
    right: 10px;
}

/deep/.el-dialog__header {
    padding: 0;
}

/deep/.el-tabs__active-bar {
    margin-left: 14px;
}

/deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    margin-left: 14px;
}

.message-color {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
}

.form-box {
    background-color: #f5f7fa;
    height: 136px;
    margin-top: 32px;
    position: relative;
}

.submit-btn {
    width: 60px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
}

.vblue-bg {
    background: #409eff;
}

.vgrey-bg {
    background: #8ca8c5;
}

.inblock-box {
    display: inline-block;
}
.remark {
    cursor: pointer;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
</style>
<style>
    .el-tooltip__popper {
        max-width: 200px;
    }
</style>
