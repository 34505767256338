<!--
 * @Author: vinota
 * @Description: 上传资料
-->
<template>
    <div>
        <div class="blue-cont">
            <div class="top-title">
                <div class="name">上传资料</div>
                <div class="current-progress">您提交时请确认上传资料与要求是否一致，提交后2-3个工作日内审核您的全套资料</div>
            </div>
        </div>
        <div class="cont-box">
            <!-- 优才/专才 -->
            <GenerForm v-if="$store.state.type!=='留学'" />
            <!-- 留学 -->
            <StudyForm v-if="$store.state.type==='留学'" />
            <div class="issue container">
                <!-- 最下面的文本段 -->
                <h3 class="ask">常见问题：</h3>
                <div>
                    <p class="bold-text">1、上传资料格式有要求吗？大小限制是多少？</p>
                    <p class="grey-text">答：支持pdf、 jpeg、 jpg、 png、word、ppt；文件大小不限制。</p>
                </div>

                <div>
                    <p class="bold-text">2、页面名称有特别字体，导致页面无法上传或演示</p>
                    <p class="grey-text">答：页面命名会出现特别符号或乱码，是字体乱码导致，建议不要用特别字体命名页面</p>
                </div>

                <div>
                    <p class="bold-text">3、上传资料后无法选择分类或样式错误？</p>
                    <p class="grey-text">答：强制刷新一下页面即可， 按键 Windows ：Ctrl+F5 | Mac ：Command+Shift+R</p>
                </div>

                <div>
                    <p class="bold-text">4、资料上传后如何重新替换？</p>
                    <p class="grey-text">答：每份资料上传后可点击重新上传，即可覆盖上次上传的资料，预览可查看最新上传的资料</p>
                </div>

                <div>
                    <p class="bold-text">5、照片是一寸还是两寸？</p>
                    <p class="grey-text">答：这个和我们说的一寸两寸不太一样，就是40mm X 50mm的规格，照相馆都是可以冲印出来的。</p>
                </div>

                <div>
                    <p class="bold-text">6、有前次婚姻子女随行，是否能不提供前妻或前夫文件？</p>
                    <p class="grey-text">答：前妻/前夫的无反对声明以及护照信息页和签名页是要提供的，申请如果不交的话，小孩可能没办法随行。</p>
                </div>

                <div>
                    <p class="bold-text">7、国外学历的成绩单是必须的吗？</p>
                    <p class="grey-text">答：是的，国外学历材料中成绩单和毕业证均为核心必要文件，一般国外学校都是可以申请成绩单的，缺失成绩单会影响入境处对该学历的认可度。</p>
                </div>

                <div>
                    <p class="bold-text">8、配偶国外本科或硕士成绩单是否为必要？</p>
                    <p class="grey-text">答：如果配偶的该国外学历涉及加分，成绩单则为必要。</p>
                </div>

                <div>
                    <p class="bold-text">9、推荐信不提供可以吗？</p>
                    <p class="grey-text">答：雇主推荐信对申请人来说是很重要的，会涉及到打分，因此是需要提供的。</p>
                </div>

                <div>
                    <p class="bold-text">10、存款证明需要冻结吗？</p>
                    <p class="grey-text">答：不要求冻结的。但是每个银行都有各自固定的格式和要求，如果银行本身要求冻结，冻结1天就可以了。</p>
                </div>

                <div>
                    <p class="bold-text">11、存款证明金额一定要三十多万吗，可以是临时存入吗？</p>
                    <p class="grey-text">答：由于香港政府要求香港优秀人才入境计划申请的申请人，有能够负担自己及家人在香港生活开销的能力，所以才需要提供这个存款证明，金额是我们根据香港现在消费水平做出的一个预估，可以临时存进去。</p>
                </div>

                <!-- <div>如以上问题无法帮助，请前往 <router-link to="/consultingexpert">咨询联系</router-link>  或直接联系您的服务团队</div> -->
                <div class="grey-text margin-bot">如以上问题无法帮助，请前往咨询联系或直接联系您的服务团队</div>

            </div>

        </div>
        <show-code v-if="$store.state.type==='优才'" />

    </div>
</template>

<script lang="javascript">
import GenerForm from "./C/GenerForm";
import StudyForm from "./C/StudyForm";
import ShowCode from "@/components/ShowCode/ShowCode.vue";
export default {
    name: "UploadData",
    components: {
        GenerForm,
        StudyForm,
        ShowCode
    },
    data() {
        return {
        };
    },
    created() {
        this.$store.commit("user");
        this.$store.commit("setType");
    },
    mounted() {},
    methods: {
    },
};
</script>

<style lang="less" scoped>
.blue-cont {
    width: 100%;
    height: 130px;
    background: linear-gradient(313deg, #74cdff 0%, #409eff 100%);
}

.top-title {
    width: 1150px;
    height: 130px;
    margin: 0 auto;
}

.name {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 1px;
    padding-top: 28px;
    margin-bottom: 12px;
}

.current-progress {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 1px;
    margin-bottom: 6px;
}

.sign-time {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
}

.cont-box {
    width: 1150px;
    margin: 0 auto;
}

.el-button--mini,
.el-button--small {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.issue {
    font-size: 14px;
    margin-top: 50px;

    & > div {
        padding: 10px 0;
    }
}

.grey-text {
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    margin-top: 8px;
    letter-spacing: 1px;
}

.bold-text {
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    letter-spacing: 1px;
}

.ask {
    font-size: 14px;
    font-weight: 600;
    color: #303133;
    margin-bottom: 2px;
}

.margin-bot {
    margin-bottom: 70px;
    margin-top: 0px;
}
</style>
